@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);
:root {
  --base-black: #18181f;
  --base-white: #e5e5ec;
  --white-10: #c9c9d0;
  --transition-time: 0.15s;
  --base-white-filter: invert(93%) sepia(6%) saturate(182%) hue-rotate(202deg) brightness(86%) contrast(91%);
}

body {
  margin: 0;
  font-family: 'Noto Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #18181f;
  background-color: var(--base-black);
  color: #e5e5ec;
  color: var(--base-white);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
    max-width: 900px;
    margin: auto;
    padding: 0 1rem;
    text-align: center;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
}

.TitleRow {
    display: flex;
    align-content: center;
    margin-bottom: 1rem;
}
.TitleRow > *:not(:first-child) {
    margin-left: 1rem;
}

.Icon {
    width: 1.6rem;
}

.Title {
    font-family: 'Rubik', sans-serif;
    font-size: 1.15rem;
}

.Info {
    font-size: 0.8rem;
    color: var(--white-10);
    display: flex;
    width: 100%;
    text-align: left;
    font-weight: 500;
}

.Imprint a {
    text-decoration: none;
    color: white;
    font-size: 10px;
}

.InfoIcon {
    /* https://codepen.io/sosuke/pen/Pjoqqp */
    -webkit-filter: invert(93%) sepia(6%) saturate(182%) hue-rotate(202deg) brightness(86%) contrast(91%);
            filter: invert(93%) sepia(6%) saturate(182%) hue-rotate(202deg) brightness(86%) contrast(91%);
    margin: 1rem 1rem 1rem 0;
}

.GitHub {
    display: flex;
    cursor: pointer;
    color: var(--white-10);
    text-decoration: underline;
}

.GitHubIcon {
    -webkit-filter: invert(93%) sepia(6%) saturate(182%) hue-rotate(202deg) brightness(86%) contrast(91%);
            filter: invert(93%) sepia(6%) saturate(182%) hue-rotate(202deg) brightness(86%) contrast(91%);
    margin: 0 1rem 0 0;
    height: 1.5rem;
}

.Spacer {
    height: 6rem;
}

.DropZone {
    background-color: rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 586px;
    border-radius: 0.4rem;
    position: relative;
}

.ImageIcon {
    -webkit-filter: var(--base-white-filter);
            filter: var(--base-white-filter);
    width: 3rem;
}

.LoadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    padding: 0 3rem;
    box-sizing: border-box;
    transition: all var(--transition-time);
}

.DropZone.Loading > .LoadingContainer {
    opacity: 1;
}

.DropCallToAction {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: absolute;
    transition: all var(--transition-time);
    z-index: 100;
}

.DropZone.Loading > .DropCallToAction, .DropZone.Done > .DropCallToAction {
    opacity: 0;
    pointer-events: none;
    z-index: auto;
    z-index: initial;
}

.ResizedContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 2rem;
    width: calc(100% - 4rem);
    height: 100%;
    transition: all var(--transition-time);
    position: absolute;
    opacity: 0;
    pointer-events: none;
}
.ResizedContainer > .Label {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.DropZone.Done > .ResizedContainer {
    opacity: 1;
    pointer-events: initial;
}

.ResizedRow {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 1rem;
    width: 100%;
    margin: 0.25rem 0 1rem 0;
}

.ResizedRow > div {
    text-align: center;
    align-self: end;
}

.ImageSize {
    color: var(--white-10);
    font-size: 0.8rem;
}

.SourceImage {
    display: none;
}

.Label {
    font-size: 0.8rem;
    color: rgba(50, 200, 255, 1);
    font-weight: bold;
}

.BottomBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.BottomBar > .ButtonContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.BottomBar > .ButtonContainer > .Button {
    margin-right: 0;
}

.Button {
    background-color: rgba(50, 200, 255, 0.5);
    padding: 0.2rem 1.3rem;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 0.4rem;
    transition: background-color 0s, box-shadow var(--transition-time);
    box-shadow: 0 0 0 2px rgba(0,0,0, 0.5), inset 0 1px 1px rgba(255, 255, 255, 0.2);
    margin: 0.8rem;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.Button:hover {
    box-shadow: 0 0 0 4px rgba(0,0,0, 0.5), inset 0 1px 1px rgba(255, 255, 255, 0.2);
}

.Button:active {
    background-color: rgb(50, 200, 255);
}

.ProgressBar {
    background-color: rgba(255, 255, 255, 0.15);
    box-shadow: 0 0 0 2px rgba(0,0,0, 0.5), inset 0 1px 1px rgba(255, 255, 255, 0.2);
    border-radius: 0.4rem;
    transition: all var(--transition-time);
    width: 100%;
    height: 1.6rem;
    overflow: hidden;
}

.ProgressBarInner {
    background-color: var(--white-10);
    border-radius: 0.4rem;
    transition: all 0.2s;
    height: 100%;
}

.TwitchPreview {
    height: 38px;
    background-color: #18181b;
    width: 320px;
    margin-bottom: 1rem;
    border-radius: 0.4rem;
    padding: 0 0.7rem;
    font-family: Inter, sans-serif;
    font-size: 13px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: color var(--transition-time), background-color var(--transition-time);
    color: rgb(239, 239, 241);
    box-shadow: 0 0 0 2px rgba(0,0,0, 0.5), inset 0 1px 1px rgba(255, 255, 255, 0.2);
}
.TwitchPreview:hover {
    box-shadow: 0 0 0 4px rgba(0,0,0, 0.5), inset 0 1px 1px rgba(255, 255, 255, 0.2);
}
.TwitchPreview.Light {
    background-color: rgb(247, 247, 248);
    color: rgb(14, 14, 16);
}
.TwitchPreview.Light > * > * > .Username {
    color: rgb(0, 128, 99);
}

.ChatMessage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.Verified {
    width: 16px;
    position: relative;
    top: 3px;
    left: -2px;
}

.Username {
    color: rgb(134, 87, 220);
    transition: color var(--transition-time);
}

.EmotePreview {
    width: 28px;
    margin-left: 0.4rem;
}

.BadgePreview {
    width: 18px;
    margin-right: 0.3rem;
}

.DarkToggle {
    display: flex;
    align-items: center;
}

.MoonIcon {
    margin-right: 0.4rem;
    height: 1.2rem;
    transition: -webkit-filter var(--transition-time);
    transition: filter var(--transition-time);
    transition: filter var(--transition-time), -webkit-filter var(--transition-time);
    -webkit-filter: invert(81%) sepia(13%) saturate(18%) hue-rotate(202deg) brightness(113%) contrast(99%);
            filter: invert(81%) sepia(13%) saturate(18%) hue-rotate(202deg) brightness(113%) contrast(99%);
}
.MoonIcon.Light {
    -webkit-filter: invert(3%) sepia(4%) saturate(2297%) hue-rotate(201deg) brightness(95%) contrast(94%);
            filter: invert(3%) sepia(4%) saturate(2297%) hue-rotate(201deg) brightness(95%) contrast(94%);
}

.ToggleSwitch {
    height: 1rem;
    width: 2rem;
    border-radius: 1rem;
    border: 3px solid;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.ToggleSwitch.Light {

}

.ToggleSwitch > .Inner {
    border: 0.375rem solid;
    width: 0;
    border-radius: 1rem;
    transform: translateX(0.1rem);
    transition: transform var(--transition-time);
}
.ToggleSwitch.On > .Inner {
    transform: translateX(1.1rem);
}

.ResizedImage {
    border-radius: 0.4rem;
    background-color: rgba(50, 200, 255, 0.1);
    box-shadow: 0 0 0 2px rgba(0,0,0, 0.5), inset 0 1px 1px rgba(255, 255, 255, 0.2);
    transition: box-shadow var(--transition-time);
    overflow: hidden;
}
.ResizedImage:active {
    background-color: rgba(50, 200, 255, 1);
}
.ResizedImage:hover {
    box-shadow: 0 0 0 4px rgba(0,0,0, 0.5), inset 0 1px 1px rgba(255, 255, 255, 0.2);
}

.ResizedImage > .Preview {
    height: calc(112px + 2rem);
    display: flex;
    align-items: center;
    justify-content: center;
}

.ResizedImage > .Info {
    color: black;
    background-color: rgba(50, 200, 255, 1);
    box-sizing: border-box;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.ResizedImage > .Info > .FileSize {
    font-weight: bold;
}

