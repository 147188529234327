.DropZone {
    background-color: rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 586px;
    border-radius: 0.4rem;
    position: relative;
}

.ImageIcon {
    filter: var(--base-white-filter);
    width: 3rem;
}

.LoadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    padding: 0 3rem;
    box-sizing: border-box;
    transition: all var(--transition-time);
}

.DropZone.Loading > .LoadingContainer {
    opacity: 1;
}

.DropCallToAction {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: absolute;
    transition: all var(--transition-time);
    z-index: 100;
}

.DropZone.Loading > .DropCallToAction, .DropZone.Done > .DropCallToAction {
    opacity: 0;
    pointer-events: none;
    z-index: initial;
}

.ResizedContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 2rem;
    width: calc(100% - 4rem);
    height: 100%;
    transition: all var(--transition-time);
    position: absolute;
    opacity: 0;
    pointer-events: none;
}
.ResizedContainer > .Label {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.DropZone.Done > .ResizedContainer {
    opacity: 1;
    pointer-events: initial;
}

.ResizedRow {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 1rem;
    width: 100%;
    margin: 0.25rem 0 1rem 0;
}

.ResizedRow > div {
    text-align: center;
    align-self: end;
}

.ImageSize {
    color: var(--white-10);
    font-size: 0.8rem;
}

.SourceImage {
    display: none;
}

.Label {
    font-size: 0.8rem;
    color: rgba(50, 200, 255, 1);
    font-weight: bold;
}

.BottomBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.BottomBar > .ButtonContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.BottomBar > .ButtonContainer > .Button {
    margin-right: 0;
}
