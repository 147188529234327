.App {
    max-width: 900px;
    margin: auto;
    padding: 0 1rem;
    text-align: center;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
}

.TitleRow {
    display: flex;
    align-content: center;
    margin-bottom: 1rem;
}
.TitleRow > *:not(:first-child) {
    margin-left: 1rem;
}

.Icon {
    width: 1.6rem;
}

.Title {
    font-family: 'Rubik', sans-serif;
    font-size: 1.15rem;
}

.Info {
    font-size: 0.8rem;
    color: var(--white-10);
    display: flex;
    width: 100%;
    text-align: left;
    font-weight: 500;
}

.Imprint a {
    text-decoration: none;
    color: white;
    font-size: 10px;
}

.InfoIcon {
    /* https://codepen.io/sosuke/pen/Pjoqqp */
    filter: invert(93%) sepia(6%) saturate(182%) hue-rotate(202deg) brightness(86%) contrast(91%);
    margin: 1rem 1rem 1rem 0;
}

.GitHub {
    display: flex;
    cursor: pointer;
    color: var(--white-10);
    text-decoration: underline;
}

.GitHubIcon {
    filter: invert(93%) sepia(6%) saturate(182%) hue-rotate(202deg) brightness(86%) contrast(91%);
    margin: 0 1rem 0 0;
    height: 1.5rem;
}

.Spacer {
    height: 6rem;
}
