@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

:root {
  --base-black: #18181f;
  --base-white: #e5e5ec;
  --white-10: #c9c9d0;
  --transition-time: 0.15s;
  --base-white-filter: invert(93%) sepia(6%) saturate(182%) hue-rotate(202deg) brightness(86%) contrast(91%);
}

body {
  margin: 0;
  font-family: 'Noto Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--base-black);
  color: var(--base-white);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
