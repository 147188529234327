.Button {
    background-color: rgba(50, 200, 255, 0.5);
    padding: 0.2rem 1.3rem;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 0.4rem;
    transition: background-color 0s, box-shadow var(--transition-time);
    box-shadow: 0 0 0 2px rgba(0,0,0, 0.5), inset 0 1px 1px rgba(255, 255, 255, 0.2);
    margin: 0.8rem;
    user-select: none;
}

.Button:hover {
    box-shadow: 0 0 0 4px rgba(0,0,0, 0.5), inset 0 1px 1px rgba(255, 255, 255, 0.2);
}

.Button:active {
    background-color: rgb(50, 200, 255);
}
